import React from "react"
// import { Link } from "gatsby"
import { Container, Image, Form, Row, Col, Button } from 'react-bootstrap';
import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

import spotifyBadge from "../images/spotify-badge.svg"
import appleBadge from "../images/apple-badge.svg"

import maryonMaass from "../images/maryon_maass1.jpg"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="masthead fixed">
      <div className="black-overlay">
        <div className="content-box">
        <h1>
          Master The Pause <br />
          <small>Maryon Maass, ERYT-500</small>
        </h1>
        <hr />
        <h2>The way to expand your consciousness is through the control of your inhalation & exhalation.</h2>

        <Row className="mt-2 mb-4">
                  <Col>
                  
                    
                    <a href="https://podcasts.apple.com/us/podcast/master-the-pause/id1533670257">
                      <Image src={appleBadge} className="m-3" fluid alt="Apple Badge" />
                    </a>
                    <a  href="https://open.spotify.com/show/14ExHSBFc1OB8zinnQET0p?si=cA6QyyjIT4qmc42zHnChSA">
                      <Image src={spotifyBadge} className="spotify-badge m-1" fluid alt="Spotify Badge" />
                    </a>
                  </Col>
                </Row>
        </div>
      </div> 
    </div>

    {/* <Container className="singleCol mb-4">
      <Row>
        <Col lg={8}>
          <h2>Upcoming Retreat</h2>
          <p><strong>Evolve For Peace</strong></p>
          <p><strong>Sunday, January 17th, 2021<br />
                      1:00 - 4:00pm</strong></p>
          <ul>
            <li>Raise Your Vibration & Vibrancy</li>
            <li>Find Peace in Your Mind and open your Heart to Flower</li>
            <li>Deepen Self Love & Compassion</li>
            <li>Health & Step into wholeness</li>
            <li>…and more!</li>
          </ul>

          <p>Purchase a ticket</p>
          <Button className="mr-1" href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=43W2GUG9BPBLN">$45</Button>
          <Button className="mr-1 ml-1" href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=95XM76FV42XDQ">$35</Button>
          <Button className="mr-1 ml-1" href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=PPDAYW483ER46">$20</Button>
 
   
        </Col>
        <Col lg={4}>
          <Image src={peaceFlyerImage} alt="flyer for Retreat" fluid />
          <p className="text-center mt-1"><Button variant="outline-dark" href={peaceFlyer}>Download PDF</Button></p>
        </Col>
      </Row>
      <hr />
    </Container> */}
    <Container className="singleCol py-4">
      <p className="text-center"><a className="btn btn-primary" href="https://www.paypal.com/donate?hosted_button_id=UV3G4TBYQ7DQ2">Donate to Maryon & Master The Pause</a></p>
      <p>This podcast is run in part from the generous donations from listeners like you. Thank you for supporting me & the podcast.</p>
      <p>Maryon Maass<br />
      <em>Namaste</em></p>
      <hr />
    </Container>
    <Container >
      <div className="singleCol">
        <Image src={maryonMaass} fluid className="portrait" alt="Portrait of Maryon Maass" />
        <p>
          Maryon Maass is an experienced Yoga, (Himalayan Tradition and Iyengar), and Feldenkrais teacher. She has also studied the art of healing Qi Gong. She studies the subtleties of body and mind, through the observation of the body, breath, and through the emotions. She leads us through subtle energies for relaxation and peace of mind.
        </p>
        <p>
          Maryon is an experienced hatha yoga and meditation. She is trained in Feldenkrais and Qi Gong , and Restorative Yoga by Judith Lasater. She has studied hatha since 1976. She lived in Rishikesh, India for 8 years (2007-2015), studying with Swami Veda Bharati, a renowned Vedic master adept in the intellectual and practical knowledge of yoga whose commentaries on the yoga sutras are the most regarded in the world. A disciple of Swami Rama of the Himalayas founder of BIO-FEEDBACK . Has also studied with BKS Iyengar, in Pune India 1985. Trained in BKS Iyengar yoga and assisted Judith Lasater Ph.D. PT.. While in India she developed the Teacher Training Program for the Himalayan Yoga Tradition Teacher Training, and was the office manager for this international TTP. Is currently mentoring students around the world.
        </p>
        <p>
          Loves teaching movements slowly with the breath. Teaches hatha yoga and/ meditation to beginning and intermediate students as well as people with chronic conditions.
        </p>
        <p>
          Has experience in training and teaching internationally. Teaches workshops, holds sessions with private clients and teaches at corporate facilities as well.
        </p>
        <p>
          Her passion is to guide people to an inward journey. To help them find balance, peace and a refreshed sense of themselves.
        </p>
        <hr />
      </div>
      
    </Container>
  
    <Container>
      <div>
        <h3 className="text-center">Testimonials</h3>
        <Row>
          <Col lg={6}>
            <blockquote>
              <p>This workshop, expertly facilitated by Mayon Maass, was so much more than just learning to meditate. One does learn how to still the mind and diaphragmatic breathing, but you also learn how to give yourself a relaxing face massage, asanas for rejuvenating joints and glands and a few acupressure points that open blocked cavities and passageways. It was when we were learning about our nostrils and what activities were associated with each one that I  had a true Ahha! moment. For as long as I can remember, my right nostril has been at least partially blocked. I have tried many nostril sprays and a neti pot with some success but it always blocked right up again. While going over accupressure points, Mayon mentioned the the pressure spot for the right nostril was just under the right armpit. As I experimented with that spot, felt the nostril and/or sinus cavity start to drain. We had already practiced alternate nostril breathing so that may have helped too. My right nostril cleared completely and I relished full inhalation in both nostrils. I have used it since then with partial success which is why i feel the alternate nostril breathing played a part. I had had many years of Hatha Yoga when I was younger but I never forgot that wonderful blend of calmness, mental clarity and boundless energy that I felt after the postures and breathing. I was reconnected with a warm memory. Mayon has a very “hands on” and nurturing teaching style. All of us students were sad when the workshop came to an end. I hope to do another, and another so that I can get it right and make it a part of my daily practice. I wholeheartedly endorse the Himalayan Yoga Art of Meditation as taught by Maryon Maass. Treat your mind, body and spirit to this workshop!

              </p>
              <p className="blockquote-footer">Sincerely, Marilyn Brende, OTR/L</p>
            </blockquote>

            <blockquote>
              <p>
                Having spent 3 hours in a class with Maryon and her Yoga Nidra teaching, I found it to be a soothing and informative way to spent my time.  Maryon is a gifted teacher who has incorporated this beautiful and rich practice deep into herself and gives it back in a clear, gentle and thoughtful manner. A part of me felt as though I was transported to her Ashram in India.
              </p>
              <p className="blockquote-footer">Lora A.</p>
            </blockquote>
          </Col>

          <Col lg={6}>
          <blockquote>
              <p>
                Thank you Maryon for the wonderful meditation teaching you provided for me! I have not been the same since. For a few years, I read about meditation and even tried it a little, but was unable to really incorporate it into my daily life. Your class gave me the tools I needed to actually sit in the “pause”.  To be fully conscious and awake, yet have my mind quiet and calm. How this has affected me is subtle yet profound. I feel, somehow, more awake. More aware. I’m sleeping better and feel calmer. My husband of almost 45 years, and he knows me better than probably anyone, said that I seem “happier” and I’m “nicer”! LOL That is good for both of us! Maryon’s method of teaching is so gentle, loving, and kind. I highly recommend Maryon’s class to anyone that wants to seek a higher level of consciousness or even just wants to get a better nights sleep!  Namaste
              </p>
              <p className="blockquote-footer">Kathy B</p>
            </blockquote>
            <blockquote>
              <p>
                Maryon´s teaching. I remember my first hatha class with her, how she lovingly approached me to provide a wonderful adjustment. Her suggestions on how to go deeper in the postures are great and offer opportunity for people of all levels and body shapes to go deeper into self-awareness and contemplation in her classes. 
              </p>
              <p className="blockquote-footer">Maria, Rishikesh/Portugal</p>
            </blockquote>
            <blockquote>
              <p>
                I participated in Maryon’s Yoga Nidra workshop on Sunday. I cannot remember when I spent a more nurturing three hours on myself. She is a gifted teacher, has a soothing disposition and led our group in a most blissful meditation. Bring on some more Maryon, we need more of your work in the world.
              </p>
              <p className="blockquote-footer">Laurie J.</p>
            </blockquote>
          </Col>
        </Row>
        <hr />
      </div>
    </Container>
    <Container>
      <div class="singleCol">
        <h3 class="text-center">Contact Me</h3>
        <Row>
          <Col md={4}>
            <p>Maryon Maass ERYT-500<br />
            Marin County, California</p>
            <p>(510) 493-8133<br />
            <a href="mailto:maryonmaass@gmail.com">maryonmaass@gmail.com</a></p>
          </Col>
          <Col md={8}>
          <Form 
        name="contact v1"
        method="post"
        // action="/success/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit="submit"
    >
    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
    <input type="hidden" name="form-name" value="contact v1" />
    <p hidden>
    <label>
        Don’t fill this out: <input name="bot-field" />
    </label>
    </p>
      


        <Row>
            <Col md={6}>
                <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control 
                        type="text" name="first-name"
                      />
                </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control 
                        type="text" name="last-name"

                      />
            </Form.Group>
            </Col>
        </Row>


            
        <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control 
                required
                type="email" 
                name="email" 
            />
        </Form.Group>

        {/* <Form.Group>
            <Form.Label>Phone</Form.Label>
            <Form.Control 
                required
                type="text" 
                name="phone" 
            />
        </Form.Group> */}
        
        <Form.Group>
            <Form.Label>Comments</Form.Label>
            <Form.Control 
                as="textarea" 
                name="comments"
                rows="3"
                
              />
        </Form.Group>
        
        <Button type="submit">Submit</Button>
        </Form>
          </Col>
        </Row>
      </div>
    </Container>
    
    
  </Layout>
)

export default IndexPage
